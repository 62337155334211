import React from 'react';
import './MarketTranslate';
import playMarket from '../../_assets/images/playMarket.png'
import playMarketApp from './../../_assets/files/playMarketApp1-6-3.apk'
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

function MarketAndroidPage() {
  const { t, i18n } = useTranslation();
 
  return (
    <div style={{position: 'relative', width: '100%', height: '500px'}}>
      <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center'}}>
        <a href={playMarketApp} download>
          <img src={playMarket} style={{width: '180px'}}/>
          <Typography
            children={t('market_download')}
            color="secondary"
            variant="h6"
          />
        </a>
      </div>
    </div>
  );
}

export default MarketAndroidPage;
